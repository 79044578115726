<template lang="pug">
.content-step2
  loading-logo(v-if="loading")
  template(v-else)
    om-select#addressbookName(
      v-model="addressbookId"
      :options="addressbooks"
      :label="$t('integrationFlow.dotmailer.listTooltip')"
      :placeholder="$t('integrationFlow.integrationModal.selectAList')"
      @input="onListChange"
      deSelectable
    )
    #addressbookName.error-msg.mt-3(v-if="hasError")
      span.text-danger(v-html="$t(`integrations.dotmailer.notifications.apiConnectError`)")
    om-select#optInType.mt-3(
      v-model="optInType"
      :options="optInTypes"
      :label="$t('integrationFlow.dotmailer.optInTypeTooltip')"
      :helperMessage="optInType ? $t(`integrationFlow.dotmailer.optInTypeHelpers.${optInType.key}`) : ''"
    )
</template>
<script>
  import { IntegrationService } from '@/services/integrations/integrationService';
  import validationByAlert from '@/mixins/integration/validationByAlert';

  export default {
    mixins: [validationByAlert],

    props: {
      settings: {
        type: Object,
        required: true,
      },

      validations: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        loading: false,
        addressbookId: null,
        addressbooks: [],
        hasError: false,
        optInType: { key: 'Single' },
        optInTypes: [
          { key: 'Single', value: 'Single' },
          { key: 'Double', value: 'Double' },
          { key: 'VerifiedDouble', value: 'Verified double' },
        ],
      };
    },

    watch: {
      addressbookId(option) {
        this.updateaddressbookSetting(option.key);
      },
      optInType(option) {
        this.updateOptInTypeSetting(option.key);
      },
    },

    async mounted() {
      // IMPORTANT to load data then set model (behavior of om-select)
      const { addressbooks, fields } = await this.loadData();

      this.updateFieldsSetting(fields);

      this.addressbooks = addressbooks;

      const { addressbookId, optInType } = this.settings.convertedSettings;

      // om-select selected option only needs "key" from object
      this.addressbookId = addressbookId ? { key: addressbookId } : null;
      this.optInType = optInType ? { key: optInType } : { key: 'Single' };
    },

    methods: {
      async loadData() {
        this.loading = true;
        const integrationService = new IntegrationService(this.$apollo);
        const result = {
          addressbooks: [],
          fields: [],
        };

        try {
          const { success, addressbooks, fields } = await integrationService.fetchIntegrationData(
            this.settings.type,
            this.settings.id,
          );

          if (success) {
            result.addressbooks = addressbooks.map((addressbook) => ({
              key: addressbook.id,
              value: addressbook.name,
            }));
            result.fields = fields;
          } else {
            this.errorNotification(result.error);
          }
          this.loading = false;
        } catch (e) {
          this.errorNotification(e.message);
          this.loading = false;
        }

        return result;
      },
      errorNotification(message) {
        this.hasError = true;
        this.$notify({
          type: 'error',
          text: message,
        });
        setTimeout(() => {
          this.$bus.$emit('integration-show-first-step');
        }, 2000);
      },

      updateaddressbookSetting(value) {
        const settings = { ...this.settings };
        settings.convertedSettings.addressbookId = value;
        this.$emit('update:settings', settings);
      },

      updateOptInTypeSetting(value) {
        const settings = { ...this.settings };
        settings.convertedSettings.optInType = value;
        this.$emit('update:settings', settings);
      },

      updateFieldsSetting(value) {
        const settings = { ...this.settings };
        settings.fields = value;
        this.$emit('update:settings', settings);
      },
    },
  };
</script>
